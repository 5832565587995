'use client';

import { FunctionComponent, MutableRefObject, useRef, useState } from 'react';

import { NavigationContentModel } from '@/services/models/Content';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { MarketingBanner } from '../../cms-content';
import { POSStoreBanner } from '../../endless-aisle/POSStoreBanner';
import { Breakpoint, Breakpoints, Default } from '../../core-ui/Breakpoints';
import { Link } from '../../core-ui/Link';
import { Logo } from '../../core-ui/Logo';

import { MiniCartButton } from '../../cart/MiniCartButton';
import { getComponents } from '../../cms-content/utils/getComponent';
import { ExchangeBanner } from '../../exchanges/ExchangeBanner';
import { useReturnsFlowState } from '../../returns/useReturnsFlowState';

import { IHeaderProps } from './IHeaderProps';

import { Desktop as DesktopNavbar } from './components/Navbar/Desktop';
import { Mobile as MobileNavbar } from './components/Navbar/Mobile';

import Search from './components/Search';
import UserButton from './components/UserButton';
import TopUtilityBar from './components/TopUtilityBar';

import S from './styles.base.module.scss';
import { Unprintable } from '../../utility/Unprintable';

/**
 * Responsive header bar containing navigation, search, cart.
 * @throws Error if navigationContent is undefined.
 */
export const DefaultHeader: FunctionComponent<IHeaderProps> = ({
  navigationContent = null,
  navbarProps
}) => {
  if (navigationContent === undefined || navigationContent === null) {
    throw new Error(
      'Navigation content is required for customer facing header.'
    );
  }

  const returnsFlowState = useReturnsFlowState();

  const searchConfig = ConfigurationService.getConfig('search');
  const isSearchEnabled = searchConfig.getSetting('enableSearch').value;
  const headerConfig = ConfigurationService.getConfig('header');
  const isBannerAtTop = headerConfig.getSetting('isBannerAtTop').value;
  const allowBannerScrollCollapse = headerConfig.getSetting(
    'allowBannerScrollCollapse'
  ).value;

  const navigationContentModel = NavigationContentModel.from(navigationContent);
  const bannerItem = navigationContentModel.headerBanner?.items[0];
  const bannerRef = useRef<HTMLDivElement>();

  const [_, setIsBannerOpen] = useState<boolean>(false);

  // Deactivating this in favor of keeping banner in the flow of the page,
  // but leaving it here in case it's needed in the future...
  // useEffect(() => {
  //   setHeaderHeight(isBannerOpen ? bannerRef.current?.offsetHeight ?? 0 : 0);
  // }, [bannerRef.current?.offsetHeight, isBannerOpen]);

  // Allow for simplified (ie. mobile style) header layout on desktop
  // displays for some brands
  const isSimplifiedDesktopLayout = useBrandLocaleValue<boolean>(
    () => ({
      default: false,
      AHNU: true
    }),
    []
  );

  const isTopUtilityBarEnabled = useBrandLocaleValue<boolean>(
    () => ({
      default: false
    }),
    []
  );

  // Same nodes but a little different order for mobile and desktop
  // so render some of them to have building blocks
  // and to avoid rendering everything twice
  const logo = (
    <Link variant="base" href="/" className={S.logo}>
      <Logo />
    </Link>
  );

  const marketingBannerNode = bannerItem && (
    <div
      className={S.bannerContainer}
      ref={bannerRef as MutableRefObject<HTMLDivElement>}
    >
      <MarketingBanner
        allowBannerScrollCollapse={allowBannerScrollCollapse}
        contentResolver={getComponents}
        item={bannerItem}
        setIsBannerOpen={setIsBannerOpen}
      />
    </div>
  );

  const exchangeBannerNode = returnsFlowState?.isExchangeModeActive && (
    <ExchangeBanner flowState={returnsFlowState} />
  );

  return (
    <Breakpoints>
      <Breakpoint media="desktop">
        <Unprintable>
          {isTopUtilityBarEnabled && <TopUtilityBar />}
          <div className={S.headerWrapper}>
            {isBannerAtTop && (
              <>
                {marketingBannerNode}
                {exchangeBannerNode}
                <POSStoreBanner />
              </>
            )}
            {isSimplifiedDesktopLayout ? (
              <header className={S.header}>
                <div className={S.columnLeft}>
                  <MobileNavbar
                    {...navbarProps?.mobile}
                    navigationContent={navigationContent}
                  />
                </div>
                {logo}
                <div className={S.columnRight}>
                  {isSearchEnabled && <Search isInlineToggleable />}
                  <UserButton />
                  <MiniCartButton />
                </div>
              </header>
            ) : (
              <header className={S.header}>
                {logo}
                <DesktopNavbar
                  {...navbarProps?.desktop}
                  navigationContent={navigationContent}
                />
                {isSearchEnabled && <Search />}
                {!isTopUtilityBarEnabled && <UserButton />}
                <MiniCartButton />
              </header>
            )}
            {!isBannerAtTop && (
              <>
                {marketingBannerNode}
                {exchangeBannerNode}
                <POSStoreBanner />
              </>
            )}
          </div>
        </Unprintable>
      </Breakpoint>

      <Default>
        <Unprintable>
          <div className={S.headerWrapper}>
            {isBannerAtTop && (
              <>
                {marketingBannerNode}
                {exchangeBannerNode}
                <POSStoreBanner />
              </>
            )}

            <header className={S.header}>
              <div className={S.columnLeft}>
                <MobileNavbar
                  {...navbarProps?.mobile}
                  navigationContent={navigationContent}
                />
                {isSearchEnabled && <Search />}
              </div>
              {logo}
              <div className={S.columnRight}>
                <UserButton />
                <MiniCartButton />
              </div>
            </header>

            {!isBannerAtTop && (
              <>
                {marketingBannerNode}
                {exchangeBannerNode}
                <POSStoreBanner />
              </>
            )}
          </div>
        </Unprintable>
      </Default>
    </Breakpoints>
  );
};

export type { IHeaderProps };
