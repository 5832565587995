'use client';

// This file needs to be a client component since it passes functions to
// other client components.

import { FunctionComponent } from 'react';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import type { IHeaderProps } from './IHeaderProps';
import { StickyHeaderAB } from './personalization/ABTests/StickyHeaderAB';
import { DefaultHeader } from './DefaultHeader';

/**
 * The main layout of the website with header, footer and content.
 *
 * @param props - Contains the content children, and CoreMedia Data.
 */
export const Header: FunctionComponent<IHeaderProps> = ({
  navigationContent,
  navbarProps,
  setHeaderHeight,
  page
}: IHeaderProps) => {
  const BrandSpecificHeader = useBrandLocaleValue<
    FunctionComponent<IHeaderProps>
  >(
    () => ({
      default: DefaultHeader
    }),
    []
  );

  const isRunningStickyHeaderABTest = useBrandLocaleValue<boolean>(
    () => ({ default: false, AHNU: true }),
    []
  );

  return isRunningStickyHeaderABTest ? (
    <StickyHeaderAB page={page}>
      <BrandSpecificHeader
        navigationContent={navigationContent}
        navbarProps={navbarProps}
        setHeaderHeight={setHeaderHeight}
        page={page}
      />
    </StickyHeaderAB>
  ) : (
    <BrandSpecificHeader
      navigationContent={navigationContent}
      navbarProps={navbarProps}
      setHeaderHeight={setHeaderHeight}
      page={page}
    />
  );
};

export default Header;
