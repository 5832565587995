import { FC } from 'react';

import LoggerService from '@/services/isomorphic/LoggerService';
import { msgf, msg } from '@/services/isomorphic/I18NService';
import { InvalidArgumentError } from '@/utils/errors';
import S from './styles.module.scss';

import { ISkipToLinkProps } from './ISkipToLinkProps';
import { SkipToLinkType } from './SkipToLinkType';
import { general_main } from "@/lang/__generated__/ahnu/general_main";
import { general_footer } from "@/lang/__generated__/ahnu/general_footer";
import { general_skip } from "@/lang/__generated__/ahnu/general_skip";

/**
 * Retrieves localized resource string for the page section to which this link skips.
 * @param skipToLinkType - SkipToLinkType.
 * @throws {InvalidArgumentError} - if skipLinkType is not a skipLinkType.
 * @returns - Resource string.
 */
export const useSkipLinkString = (skipToLinkType: SkipToLinkType): string => {
  // While this `useGroupString` hook might look like madness, it is necessary because  //
  // resource string identifiers need to be statically analyzable by the i18n tooling. We
  // do have the option of calling `msgDynamic` instead, but that would require us to load
  // all resource strings at runtime and make the value of this string async. To get the
  // greatest performance benefit we use the switch statement here instead.
  if (!skipToLinkType)
    throw new InvalidArgumentError(
      `Skip link type is required. \`skipLinkType\` was "${skipToLinkType}"\``
    );
  switch (skipToLinkType) {
    case SkipToLinkType.Main:
      return msg(general_main);
    case SkipToLinkType.Footer:
      return msg(general_footer);
    default: {
      LoggerService.warn(
        `Skip link type "${skipToLinkType}" is not a known value.`
      );
      return '';
    }
  }
};

/**
 * Component that provides a user the ability to skip navigation content.
 * Accessibility specs are detailed here: {@link https://www.w3.org/TR/WCAG20-TECHS/G1.html}.
 */
export const SkipToLink: FC<ISkipToLinkProps> = ({ type }) => {
  /**
   * Maps the types prop to array to allow for single or multiple rendering with same map function.
   */
  const types = Array.isArray(type) ? type : [type];

  return (
    <>
      {types.map((type) => (
        <a
          tabIndex={0}
          href={`#${type}`}
          key={`${type}-skip-link`}
          className={S.link}
        >
          {`${msgf(general_skip, {
            content: type
          })}`}
        </a>
      ))}
    </>
  );
};
