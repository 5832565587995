import dynamic from 'next/dynamic';
import SizeChartSkeleton from '../SizeChartSkeleton';

const DynamicSizeChart = dynamic(
  () =>
    import(
      /* webpackChunkName: "DynamicSizeChart" */
      '..'
    ).then((imported) => imported.SizeChart),
  { loading: () => <SizeChartSkeleton /> }
);

export default DynamicSizeChart;
