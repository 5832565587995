'use client';

import { msg } from '@/services/isomorphic/I18NService';
import { useWindowHeight } from '@react-hook/window-size';
import classNames from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { Button } from '../../core-ui/Button';
import { Icon, IconSizeProp, IconTypes } from '../../core-ui/Icon';

import { useScroll } from '../../core-ui/hooks/useScroll';
import S from './styles.module.scss';
import { general_backToTop } from "@/lang/__generated__/ahnu/general_backToTop";
import { general_backToTopButton } from "@/lang/__generated__/ahnu/general_backToTopButton";

/**
 * A button that scrolls the user to the top of the page.
 *
 * 1. Only appears on pages that are larger than 3x the viewport/element height.
 * 2. Appears after both conditions:
 *  a. The user has scrolled beyond 2x the viewport/element height.
 *  B. The user has begun scrolling up.
 * 3. Disappears after either conditions:
 *  a. The user has scrolled before 2x the viewport/element height.
 *  B. The user has begun scrolling down.
 */
export const BackToTopButton: FC = ({ ...props }) => {
  /**
   * Visible is whether the button should show at all. By default, it is false.
   * If the page is larger than 4x the viewport/element height, it is true.
   */
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const { deltaY, scrollY } = useScroll(50);

  const windowHeight = useWindowHeight();

  const targetHeight = useBrandLocaleValue<number>(
    () => ({
      default: windowHeight * 3,
      AHNU: windowHeight
    }),
    [windowHeight]
  );

  const buttonIcon = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.ArrowUp,
      AHNU: IconTypes.ChevronUp
    }),
    []
  );

  const scrollBehavior = useBrandLocaleValue<ScrollBehavior>(
    () => ({
      default: 'smooth',
      AHNU: 'auto'
    }),
    []
  );

  const buttonPosition = useBrandLocaleValue<'right' | 'left'>(
    () => ({
      default: 'right',
      AHNU: 'left'
    }),
    []
  );

  const isHidden = useMemo(() => {
    const isScrollingUp = deltaY > 0;
    const isBeyondTargetHeight = scrollY > targetHeight;

    return !isScrollingUp || !isBeyondTargetHeight;
  }, [deltaY, scrollY]);

  /**
   * If possible scrollable height is greater than the target height,.
   */
  useEffect(() => {
    // If the page is already determined to show the button, don't bother checking.
    if (shouldDisplay) return;
    if (targetHeight > document.body.scrollHeight || targetHeight > scrollY) {
      setShouldDisplay(true);
    }
  }, [windowHeight, targetHeight, scrollY]);

  return (
    <>
      {shouldDisplay && (
        <Button
          variant="secondary"
          className={classNames(
            S.button,
            {
              [S.hidden]: isHidden
            },
            {
              [S.right]: buttonPosition === 'right'
            },
            {
              [S.left]: buttonPosition === 'left'
            }
          )}
          title={msg(general_backToTop)}
          ariaLabel={msg(general_backToTop)}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: scrollBehavior
            });
          }}
          {...props}
        >
          <div className={S.content}>
            <span className={S.label}>{msg(general_backToTopButton)}</span>
            <Icon
              className={S.icon}
              size={IconSizeProp.SizeLG}
              icon={buttonIcon}
            />
          </div>
        </Button>
      )}
    </>
  );
};
