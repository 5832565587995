/**
 * Returns `true` if the specified value is nullish.
 * That is, if the value is either `null` or `undefined`.
 *
 * Not all [falsy](https://developer.mozilla.org/en-US/docs/Glossary/Falsy)
 * values are nullish. But all nullish values are falsy.
 *
 * @param value - The value to check.
 * @returns `true` if the specified value is nullish, and `false` otherwise.
 */
export const isNullish = (value: unknown): value is null | undefined => {
  return value === undefined || value === null;
};
