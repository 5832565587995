'use client';

import { FunctionComponent } from 'react';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { Link } from '../../../../../../../core-ui/Link';
import { INavigationLinkProps } from './INavigationLinkProps';

import S from '../../styles.base.module.scss';

/**
 * Navigation item for use when specifying nav structure via config.
 */
const NavigationLink: FunctionComponent<INavigationLinkProps> = ({
  item,
  onClick
}) => {
  return (
    <li className={S.navItem} key={item.id}>
      <Link
        className={S.navLink}
        href={`${item.href}`}
        onClick={onClick}
        interactionDetails={{
          action: EventType.NavigationLink,
          link: {
            linkName: item.label,
            linkCategory: 'top-navigation',
            linkSection: item.label
          }
        }}
      >
        {item.label}
      </Link>
    </li>
  );
};

export default NavigationLink;
