'use client';

import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { observer } from 'mobx-react-lite';
import { FunctionComponent } from 'react';
import ProductDetailsVariant from '../ProductDetailsVariant';
import { AhnuVariationPanel } from './AhnuVariationPanel';
import { DefaultVariationPanel } from './DefaultVariationPanel';
import IVariationPanelProps from './IVariationPanelProps';

/**
 * VariationPanel to contain size/color selector, add to cart.
 */
export const VariationPanel = observer(function VariationPanel({
  variant = ProductDetailsVariant.Default,
  product,
  children
}: IVariationPanelProps) {
  const BrandSpecificVariationPanel = useBrandLocaleValue<
    FunctionComponent<IVariationPanelProps>
  >(
    () => ({
      default: DefaultVariationPanel,
      AHNU: AhnuVariationPanel
    }),
    []
  );

  return (
    <BrandSpecificVariationPanel variant={variant} product={product}>
      {children}
    </BrandSpecificVariationPanel>
  );
});
