'use client';

import { useState, FunctionComponent } from 'react';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { Drawer } from '../../../../../../../core-ui/Drawer';

import {
  Icon,
  IconSizeProp,
  IconTypes
} from '../../../../../../../core-ui/Icon';
import { Link } from '../../../../../../../core-ui/Link';

import Block from '../Block';
import NavigationBack from '../NavigationBack';

import { ICategoryProps } from './ICategoryProps';

import S from '../../styles.base.module.scss';

/**
 * Renders a button for the mobile navigation that (once pressed) opens a
 * Drawer component which contains blocks (further layers of navigation).
 */
const Category: FunctionComponent<ICategoryProps> = ({
  category,
  navigationClose
}) => {
  const [visible, setVisible] = useState(false);
  const hasChildren = category.blocks.length > 0;

  // Check to see if the brand has a custom icon size for the category nav icon.
  const navCategoryIconSize = useBrandLocaleValue<IconSizeProp>(
    () => ({
      default: IconSizeProp.SizeSM
    }),
    []
  );

  if (!hasChildren) {
    return (
      <li className={S.navItem} key={category.id}>
        <Link
          className={S.navLink}
          href={`/c${category.href}`}
          onClick={navigationClose}
          interactionDetails={{
            action: EventType.NavigationLink,
            link: {
              linkName: category.label,
              linkCategory: 'top-navigation',
              linkSection: category.label
            }
          }}
        >
          {category.label}
        </Link>
      </li>
    );
  }

  return (
    <li className={S.navItem} key={category.id}>
      <button
        type="button"
        className={S.navLink}
        onClick={() => {
          setVisible(true);
        }}
        aria-label={category.label}
      >
        {category.label}
        <span className={S.navIcon}>
          <Icon icon={IconTypes.AngleRight} size={navCategoryIconSize} />
        </span>
      </button>
      <Drawer
        rootClassName={S.innerDrawerRoot}
        drawerClassName={S.innerDrawer}
        curtainClassName={S.innerDrawerCurtain}
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
        fromLeft
      >
        <NavigationBack onClick={() => setVisible(false)} />
        <ul className={S.nav}>
          <li className={S.navItem}>
            <span className={`${S.navLink} ${S.navLinkHeader}`}>
              {category.label}
            </span>
          </li>
          {category.blocks.map((block) => (
            <Block
              key={block.id}
              block={block}
              navigationClose={navigationClose}
            />
          ))}
        </ul>
      </Drawer>
    </li>
  );
};

export default Category;
