'use client';

import { msg } from '@/services/isomorphic/I18NService';
import { FunctionComponent } from 'react';

import { RecommendationContext } from '@/configs/recommendations';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useProductVM } from '@/react/hooks/useProductVM';
import { useRecommendedProducts } from '@/react/hooks/useRecommendedProducts';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { IProduct } from '@/services/models/Product';
import { observer } from 'mobx-react-lite';
import { Button } from '../../../core-ui/Button';
import { Modal } from '../../../core-ui/Modal';
import { ModalHeader } from '../../../core-ui/Modal/ModalHeader';
import { RecommendationsCarousel } from '../../recommendation/RecommendationsCarousel';

import { ProductDetailsPanel } from '../../../product/ProductDetailsPanel';

import { useViewModel } from '../../../../hooks/useViewModel';
import ProductDetailsVariant from '../../../product/ProductDetailsVariant';

import { useReturnsFlowState } from '../../../returns/useReturnsFlowState';
import S from './styles.module.scss';
import { product_tiles_quickView_button_text } from "@/lang/__generated__/ahnu/product_tiles_quickView_button_text";
import { product_tiles_quickView_title } from "@/lang/__generated__/ahnu/product_tiles_quickView_title";

export interface IQuickviewProps {
  /** The product to be displayed in quickview format. */
  product: IProduct;
}

/** The quickview card component. */
const QuickviewButton: FunctionComponent<IQuickviewProps> = observer(
  // Using a named function here to make it easier to debug. Otherwise MobX will show the component as `observerComponent`.
  function QuickviewButton({ product }) {
    const { isExchangeModeActive } = useReturnsFlowState() ?? {};

    const { products, isRecommendationEnabled } = useRecommendedProducts(
      RecommendationContext.Quickview
    );

    const [vm] = useViewModel({
      isModalOpen: false,
      isPanelResolved: false,

      handleModalOpen: () => {
        vm.isModalOpen = true;
      },

      handleModalClose: () => {
        // Resets component on modal close.
        vm.isModalOpen = false;
        vm.isPanelResolved = false;
      },

      resolvePanel: () => {
        // Hides first modal.
        vm.isPanelResolved = true;
      }
    });

    const productVM = useProductVM(product);

    return (
      <>
        <Button
          variant="link"
          onClick={vm.handleModalOpen}
          interactionDetails={{
            action: EventType.QuickviewShow
          }}
        >
          {msg(product_tiles_quickView_button_text)}
        </Button>
        <Modal isOpen={vm.isModalOpen} onClose={vm.handleModalClose}>
          <div
            className={classes(S.modalContainer, {
              [S.hide]: vm.isPanelResolved
            })}
          >
            <ModalHeader
              onClose={vm.handleModalClose}
              className={S.modalHeader}
            >
              {msg(product_tiles_quickView_title)}
            </ModalHeader>
            <div className={S.modalBody}>
              <ProductDetailsPanel
                variant={
                  isExchangeModeActive
                    ? ProductDetailsVariant.ExchangeQuickview
                    : ProductDetailsVariant.Quickview
                }
                product={productVM}
                onAddToCart={vm.handleModalClose}
              />
              {isRecommendationEnabled && (
                <RecommendationsCarousel products={products} />
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
);

export default QuickviewButton;
