'use client';

import { FunctionComponent, useState } from 'react';

import { msg } from '@/services/isomorphic/I18NService';
import { Icon, IconSizeProp, IconTypes } from '../../core-ui/Icon';
import { Button } from '../../core-ui/Button';

import S from './styles.module.scss';
import { general_addToWishList } from "@/lang/__generated__/ahnu/general_addToWishList";

export interface IWishlistHeartProps {
  /** Current product identifier, probably should be the UUID after model integration. */
  slug: string;
}

/** The wishlist heart icon that fills in when clicked. */
export const WishlistHeart: FunctionComponent<IWishlistHeartProps> = ({
  slug
}) => {
  /** Controls whether wishlist heart is filled in or not. */
  const [active, setActive] = useState(false);
  const addToWishlistHandler = (pid: string): void => {
    /** Add to wishlist logic. */
    setActive(!active);
  };

  return (
    <Button
      variant="text"
      className={S.container}
      onClick={() => {
        addToWishlistHandler(slug);
      }}
      ariaLabel={msg(general_addToWishList)}
    >
      <Icon
        icon={active ? IconTypes.HeartSolid : IconTypes.Heart}
        size={IconSizeProp.Size2X}
      />
    </Button>
  );
};
