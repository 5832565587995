'use client';

import Image from 'next/image';

import {
  type IProductVM,
  DisplayVariationAttributeType
} from '@/react/view-models/ProductVM';
import LoggerService from '@/services/isomorphic/LoggerService';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import S from './styles.module.scss';

export interface IShoeHeightSelectorProps {
  /** The product to display shoe height options for. */
  product: IProductVM;
}

/**
 * The possible shoe height styles.
 */
type ShoeHeight = 'low-cut' | 'mid-cut' | 'high-cut';

/**
 * The metadata for a shoe height style.
 */
interface IShoeHeightMetadata {
  /**
   * The unique identifier for the style.
   */
  id: string;

  /**
   * A human-readable name for the style.
   */
  name: string;

  /**
   * The source URL for the style icon.
   */
  src: string;

  /**
   * The width of the style icon.
   */
  width: number;

  /**
   * The height of the style icon.
   */
  height: number;
}

const SHOE_HEIGHT_ORDER = [
  'low-cut',
  'mid-cut',
  'high-cut'
] as Array<ShoeHeight>;

// TODO: Replace this to be dynamically determined from product group metadata.
const STYLE_DATA: Record<ShoeHeight, IShoeHeightMetadata> = {
  'low-cut': {
    id: 'low-cut',
    name: 'Low',
    src: '/images/icon_low-shoe.svg',
    width: 95,
    height: 55
  },
  'mid-cut': {
    id: 'mid-cut',
    name: 'Mid',
    src: '/images/icon_mid-shoe.svg',
    width: 95,
    height: 55
  },
  'high-cut': {
    id: 'high-cut',
    name: 'High',
    src: '/images/icon_high-shoe.svg',
    width: 95,
    height: 55
  }
};

/** The Style selector component for the PDP. */
const ShoeHeightSelector = observer(function ShoeHeightSelector({
  product
}: IShoeHeightSelectorProps) {
  const variations = product
    .getVariationAttributesByType(DisplayVariationAttributeType.ShoeHeight)
    .slice(0); // clone the array

  // sort the variations to be in a consistent order
  variations.sort(
    (a, b) =>
      SHOE_HEIGHT_ORDER.indexOf(a.value as ShoeHeight) -
      SHOE_HEIGHT_ORDER.indexOf(b.value as ShoeHeight)
  );

  return (
    <div className={S.container}>
      {variations.map(({ type, value, isSelected, isSelectable }) => {
        // TODO: Since product group names are unpredictable, there may not be a 1:1
        // mapping between the variation value and the style data. As such, replace this
        // with a more dynamic solution once the product group metadata is available.
        const data = STYLE_DATA[value as keyof typeof STYLE_DATA];

        if (!data) {
          LoggerService.warn(
            `No style data found for shoe height variation: ${value}`
          );
          return undefined;
        }

        return (
          <button
            key={value}
            type="button"
            className={classNames(S.styleButton, { [S.active]: isSelected })}
            disabled={!isSelectable}
            onClick={() =>
              isSelectable && product.selectVariationAttribute(type, value)
            }
          >
            <Image
              priority
              src={data.src}
              alt={data.name}
              width={data.width}
              height={data.height}
              className={S.image}
            />
            <span className={S.nameLabel}>{data.name}</span>
          </button>
        );
      })}
    </div>
  );
});

export default ShoeHeightSelector;
