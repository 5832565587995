import {
  DecisionIDOf,
  IDecision,
  ExperienceID
} from '@/services/isomorphic/PersonalizationService';
import { Nullable } from '@/type-utils';
import { createContext } from 'react';

/**
 * A context that contains an experience and the chosen decision id.
 */
export interface IExperienceContext<T extends ExperienceID> {
  /**
   * The experience that holds the various decisions.
   */
  experienceID: string;

  /**
   * The decision object that contains an id and props for the decision component, it is null to start, but
   * gets a value after querying the `PersonalizationService`.
   */
  decision: Nullable<IDecision<T, DecisionIDOf<T>>>;
}

export const ExperienceContext =
  createContext<Nullable<IExperienceContext<ExperienceID>>>(null);
