'use client';

import { FunctionComponent, useState, useCallback } from 'react';
import { NavigationContentModel } from '@/services/models/Content';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { msg } from '@/services/isomorphic/I18NService';
import { Drawer } from '../../../../../core-ui/Drawer';
import { Icon, IconSizeProp, IconTypes } from '../../../../../core-ui/Icon';

import Category from './components/Category';
import NavigationBack from './components/NavigationBack';
import NavigationLink from './components/NavigationLink';
import { INavigationLink } from './components/NavigationLink/INavigationLinkProps';
import { IMobileNavbarProps } from './IMobileNavbarProps';

import S from './styles.base.module.scss';
import { header_navigation_open } from "@/lang/__generated__/ahnu/header_navigation_open";

/**
 * The Mobile navbar component that acts as a drawer.
 */
export const Mobile: FunctionComponent<IMobileNavbarProps> = ({
  navigationContent
}) => {
  const navigationContentModel = NavigationContentModel.from(navigationContent);
  const categories = navigationContentModel.headerCategories;
  const [open, setOpen] = useState(false);

  // Provide an option to override the CMS values for the menu.
  // The 'menu' config structure allows us to set a basic, static
  // menu structure of links.
  const menuConfig = ConfigurationService.getConfig('menu');
  const mainMenu = menuConfig.getSetting('mainMenu').value;
  const isCmsOverride = mainMenu.overrideCmsValues.value;
  const configLinks = mainMenu.links.value?.map(({ href, id, label }) => {
    return {
      id: id.value,
      label: label.value,
      href: href.value
    } as INavigationLink;
  });

  const MenuIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.Bars,
      AHNU: IconTypes.BarsLight
    }),
    []
  );

  // Show the navigation back button on parent / root drawer.
  const showBackButtonOnParentDrawer = useBrandLocaleValue<boolean>(
    () => ({
      default: true
    }),
    []
  );

  const doOpen = useCallback(() => setOpen(true), []);
  const doClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <button
        className={S.buttonWithIcon}
        onClick={doOpen}
        type="button"
        title={msg(header_navigation_open)}
        aria-label={msg(header_navigation_open)}
      >
        <Icon icon={MenuIconType} size={IconSizeProp.SizeLG} />
      </button>

      <Drawer
        open={open}
        onClose={doClose}
        fromLeft
        drawerClassName={S.parentDrawer}
      >
        {showBackButtonOnParentDrawer && (
          <NavigationBack onClick={() => setOpen(false)} />
        )}
        <ul className={S.nav}>
          {!isCmsOverride ? (
            <>
              {categories.map((category) => {
                return (
                  <Category
                    key={category.id}
                    category={category}
                    navigationClose={doClose}
                  />
                );
              })}
            </>
          ) : (
            configLinks.map((item) => {
              return (
                <NavigationLink
                  key={item.id}
                  item={item}
                  onClick={() => setOpen(false)}
                />
              );
            })
          )}
        </ul>
      </Drawer>
    </>
  );
};
