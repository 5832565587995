import { FunctionComponent } from 'react';
import { Link } from '@/react/components/core-ui/Link';
import I18NService, { msg } from '@/services/isomorphic/I18NService';
import { Icon, IconTypes, IconSizeProp } from '@/react/components/core-ui/Icon';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useGlobalContext } from '@/react/hooks/useGlobalContext';
import UserButton from '../UserButton';

import S from './styles.module.scss';
import { header_utilBar_guaranteeLink } from "@/lang/__generated__/ahnu/header_utilBar_guaranteeLink";
import { header_utilBar_storeLocator } from "@/lang/__generated__/ahnu/header_utilBar_storeLocator";
import { header_utilBar_account } from "@/lang/__generated__/ahnu/header_utilBar_account";

/**
 * Top utility bar above the nav bar with a promotional link and My Account / Store Locator links.
 */
export const TopUtilityBar: FunctionComponent = () => {
  const { user } = useGlobalContext();
  const locale = I18NService.currentLocale.locale || 'US';
  const flagIconType = `${locale}Flag` as keyof typeof IconTypes;

  return (
    <div className={S.utilityWrapper}>
      <div className={S.promoLink}>
        <Link className={S.link} href="/guarantee" variant="text-underline">
          {msg(header_utilBar_guaranteeLink)}
        </Link>
      </div>
      <div className={S.utilityLinks}>
        <div className={S.storeLocator}>
          <Link className={S.link} href="/storelocator" variant="text">
            <Icon
              className={S.icon}
              icon={IconTypes.MapMarker}
              size={IconSizeProp.Size1X}
            />
            {msg(header_utilBar_storeLocator)}
          </Link>
        </div>
        <div className={S.account}>
          <UserButton />
          <Link className={S.link} href="/account" variant="text">
            {msg(header_utilBar_account)}
          </Link>
        </div>
        <Link className={S.link} href="/choose-country">
          <Icon
            className={classes(S.icon, S.flag)}
            icon={IconTypes[flagIconType]}
            size={IconSizeProp.SizeLG}
          />
        </Link>
      </div>
    </div>
  );
};

export default TopUtilityBar;
