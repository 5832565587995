'use client';

import { Button } from '@/react/components/core-ui/Button';

import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import type { IProductVM } from '@/react/view-models/ProductVM';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import S from './styles.module.scss';

interface IGenericSelectorProps {
  /**
   * The product view model.
   */
  product: IProductVM;

  /**
   * The display variation attribute type to render a selector for.
   */
  variationType: DisplayVariationAttributeType;

  /**
   * The function to use to sort the variation values.
   * @param a - The first value to compare.
   * @param b - The second value to compare.
   * @returns A number indicating the sort order.
   */
  sortFn?: (a: string, b: string) => number;
}

/** The Group selector component for the PDP. */
const GenericSelector = observer(function GenericSelector({
  product,
  variationType,
  sortFn = (a, b) => a.localeCompare(b)
}: IGenericSelectorProps) {
  const variations = product
    .getVariationAttributesByType(variationType)
    .slice(0); // Clone the array to avoid modifying the original.

  variations.sort((vA, vB) => sortFn(vA.value, vB.value));

  return (
    <div className={S.container}>
      {variations.map(({ type, value, isSelected, isSelectable }) => (
        <Button
          key={value}
          variant="tertiary"
          className={classNames(S.button, { [S.active]: isSelected })}
          disabled={!isSelectable}
          onClick={() =>
            isSelectable && product.selectVariationAttribute(type, value)
          }
        >
          {value}
        </Button>
      ))}
    </div>
  );
});

export default GenericSelector;
