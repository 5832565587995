'use client';

import { observer } from 'mobx-react-lite';
import { FunctionComponent, useCallback, useState } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { PageService } from '@/services/isomorphic/PageService';
import { PageType } from '@/services/models/Page';
import CartButton from '../../navigation/Header/components/CartButton';
import { HoverIntent } from '../../utility/HoverIntent';
import { MiniCart } from '../MiniCart';

import { useGlobalContext } from '../../../hooks/useGlobalContext';

import S from './styles.module.scss';

/**
 * Mini Cart Button used to control the Mini Cart drop down in the header.
 */
export const MiniCartButton: FunctionComponent = observer(() => {
  const [openMiniCart, setOpenMiniCart] = useState(false);
  const { cart } = useGlobalContext();

  const handleOpenCart = useCallback(() => setOpenMiniCart(true), []);
  const handleCloseCart = useCallback(() => setOpenMiniCart(false), []);

  const shouldOpenCart = openMiniCart && cart.totalProductQuantity > 0;

  // Check to see if the current page is the cart page.
  const { page } = PageService;
  const pageType = page ? page.pageType : null;
  const isCartPage = pageType === PageType.Cart;

  return (
    <HoverIntent
      onHoverIn={handleOpenCart}
      onHoverOut={handleCloseCart}
      disabled={isCartPage}
    >
      <div className={S.container}>
        <CartButton count={cart.totalProductQuantity} />
        <div
          className={classes(S.miniCart, {
            // Only add the `visible` class if `shouldOpenCart` is truthy.
            [S.visible]: !!shouldOpenCart
          })}
          aria-hidden={!shouldOpenCart}
        >
          <MiniCart cart={cart} />
        </div>
      </div>
    </HoverIntent>
  );
});
