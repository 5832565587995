'use client';

import { ReactElement, ReactNode, useContext } from 'react';

import {
  DecisionIDOf,
  ExperienceID,
  PropsOfDecision
} from '@/services/isomorphic/PersonalizationService/campaigns';
import { Nullable } from '@/type-utils';
import { InvalidStateError } from '@/utils/errors';
import SkeletonLoader from '../../utility/SkeletonLoader';
import { ExperienceContext } from '../DynamicExperience/ExperienceContext';

import S from './styles.module.scss';

export interface IPersonalizationDecisionProps<
  EID extends ExperienceID,
  DecisionID extends DecisionIDOf<EID>
> {
  /**
   * The decision id, a property on the JSON payload.
   */
  id: DecisionID;

  /**
   * The fallback that renders instead of the component.
   */
  fallback?: ReactNode;

  /**
   * The children of the decision experience, allows you to feed props into it.
   * @param props - Props that are relevant to this specific decision.
   * @returns The children components that have been fed the decision props.
   */
  children: (
    /** Props must be able to accept a null in order to pre-render the children before the official props have come in. */
    props: Nullable<PropsOfDecision<DecisionID>>
  ) => ReactNode | Array<ReactNode>;
}

/**
 * The decision component holds a personalization experience, but does not render it until
 * the choice has been made by the `PersonalizationService`.
 * @param props - The props for this component.
 * @returns The children component or a placeholder until the choice is made.
 * @throws If not wrapped by DynamicExperience component.
 */
export const Decision = <
  const EID extends ExperienceID,
  const DID extends DecisionIDOf<EID>
>({
  children,
  id,
  fallback = <SkeletonLoader />
}: IPersonalizationDecisionProps<EID, DID>): ReactElement | null => {
  const experienceContext = useContext(ExperienceContext);

  if (!experienceContext) {
    throw new InvalidStateError(
      'Decision must be wrapped in a DynamicExperience component.'
    );
  }
  const { decision } = experienceContext;

  if (!decision)
    return (
      <div className={S.loadingWrapper}>
        <div className={S.hiddenDecision}>
          <div className={S.fallbackWrapper}>{fallback}</div>
          {children(null)}
        </div>
      </div>
    );

  const { decisionID, props } = decision;

  if (decisionID !== id) return null;

  return <>{children(props as PropsOfDecision<DID>)}</>;
};
