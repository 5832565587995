'use client';

import { FunctionComponent, useState } from 'react';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import {
  Icon,
  IconSizeProp,
  IconTypes
} from '../../../../../../../core-ui/Icon';
import { Drawer } from '../../../../../../../core-ui/Drawer';
import { Link } from '../../../../../../../core-ui/Link';

import NavigationBack from '../NavigationBack';
import { IBlockProps } from './IBlockProps';

import S from '../../styles.base.module.scss';

/**
 * Renders a button for the mobile navigation, which once pressed
 * opens the Drawer component containing the links of a block.
 */
const Block: FunctionComponent<IBlockProps> = ({ block, navigationClose }) => {
  const [visible, setVisible] = useState(false);
  /**
   * The ICategoryBlock is a weird structure, it is an array, which
   * pretty much exclusively contains a single item, which contains the actual links.
   */
  const data = block.links[0];
  const label = block.label ?? data.label;

  // Check to see if the brand has a custom icon size for the category nav icon.
  const navCategoryIconSize = useBrandLocaleValue<IconSizeProp>(
    () => ({
      default: IconSizeProp.SizeSM
    }),
    []
  );

  return (
    <li className={S.navItem} key={block.id}>
      <button
        type="button"
        className={S.navLink}
        onClick={() => {
          setVisible(true);
        }}
        aria-label={label}
      >
        {label}
        <span className={S.navIcon}>
          <Icon icon={IconTypes.AngleRight} size={navCategoryIconSize} />
        </span>
      </button>
      <Drawer
        rootClassName={S.innerDrawerRoot}
        drawerClassName={S.innerDrawer}
        curtainClassName={S.innerDrawerCurtain}
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
        fromLeft
      >
        <NavigationBack onClick={() => setVisible(false)} />
        <ul className={S.nav} key={block.id || data.id}>
          <li className={S.navItem}>
            <span className={`${S.navLink} ${S.navLinkHeader}`}>{label}</span>
          </li>
          {data.links?.map((link) => {
            return (
              <li className={S.navItem} key={link.id}>
                <Link
                  className={S.navLink}
                  href={`/c${link.href}`}
                  onClick={navigationClose}
                  interactionDetails={{
                    action: EventType.NavigationLink,
                    link: {
                      linkName: link.label,
                      linkCategory: 'top-navigation',
                      linkSection: label
                    }
                  }}
                >
                  {link.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </Drawer>
    </li>
  );
};

export default Block;
