'use client';

import { FunctionComponent, PropsWithChildren } from 'react';

import { IColorVariationAttribute } from '@/services/models/Product/variation-attributes';
import { Link } from '../../../core-ui/Link';
import { HoverIntent } from '../../../utility/HoverIntent';

import S from './styles.module.scss';

export interface IColorOptionProps {
  /** The color to display. */
  color: IColorVariationAttribute;

  /** Link to navigate to when clicked. */
  href: string | URL;

  /** Callback to run on hover. */
  onHover?: () => void;

  /** Mouse leave action. */
  onLeave?: () => void;
}

/** An option or "bubble" for the color selector on the product tile. */
export const ColorOption: FunctionComponent<IColorOptionProps> = ({
  color,
  href,
  onHover,
  onLeave
}) => {
  return (
    <HoverIntent onHoverIn={onHover} onHoverOut={onLeave} delay={0}>
      <Link href={href instanceof URL ? href.toString() : href}>
        <div
          className={S.colorOption}
          style={{
            // The fallback color allows the swatch exist even if it is misconfigured, since
            // the color still exists for this model.
            backgroundColor: color.hexValue ? color.hexValue : '#fff',
            backgroundImage: color.primaryImage
              ? `url(${color.primaryImage})`
              : undefined
          }}
        />
      </Link>
    </HoverIntent>
  );
};

/** Holds the color options of a product tile. */
const ColorSelector: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <div className={S.colorSelector}>{children}</div>;
};

export default ColorSelector;
