import { DTO } from '@/type-utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { InvalidOperationError, InvalidStateError } from '@/utils/errors';
import OrderLookupService from '@/services/isomorphic/OrderLookupService';
import { removeNullish, toStatelessImmutable } from '@/utils/object-utils';
import {
  HistoricOrder,
  IHistoricOrder
} from '@/services/isomorphic/OrderLookupService/data-structures';
import UserService from '@/services/isomorphic/UserService';
import { IMaskedHistoricOrder } from '../../isomorphic/OrderLookupService/data-structures/IMaskedHistoricOrder';
import { IOrderHistory } from './IOrderHistory';
import Model from '../Model/Model';
import { IOrderLookupParams } from './IOrderLookupParams';
import { UserType } from '../User';

/**
 * Represents an Order History: a data structure that, given specific order
 * lookup options, can fetch and page the matching order hits.
 */
export default class OrderHistoryModel<
    T extends IMaskedHistoricOrder = HistoricOrder
  >
  extends Model<DTO<IOrderHistory<T>>>
  implements IOrderHistory<T>
{
  @observable private _pages: Array<Array<T>>;

  @observable private _pageSize: number;
  @observable private _totalItems: number;
  @observable private _currentPage: number;
  @observable private _isLoadingNewPage: boolean;

  /** @inheritdoc */
  public readonly lookupParams?: IOrderLookupParams;

  /** @inheritdoc */
  public constructor(orderHistory: DTO<IOrderHistory<T>>) {
    super(orderHistory);

    this._pages = [...orderHistory.pages] as unknown as Array<Array<T>>;

    this._pageSize = orderHistory.pageSize;
    this._totalItems = orderHistory.totalItems;
    this._currentPage = orderHistory.currentPage;

    this._isLoadingNewPage = false;

    this.lookupParams = orderHistory.lookupParams;

    makeObservable(this);
  }

  /** @inheritdoc */
  public get allItems(): Array<T> {
    return removeNullish(this._pages).flat();
  }

  /** @inheritdoc */
  public get pageSize(): number {
    return this._pageSize;
  }

  /** @inheritdoc */
  public get totalItems(): number {
    return this._totalItems;
  }

  /** @inheritdoc */
  public get currentPage(): number {
    return this._currentPage;
  }

  /** @inheritdoc */
  public get isLoadingNewPage(): boolean {
    return this._isLoadingNewPage;
  }

  /** @inheritdoc */
  @computed public get pages(): Array<Array<T>> {
    return this._pages;
  }

  /** @inheritdoc */
  @computed public get totalPages(): number {
    return Math.ceil(this._totalItems / this.pageSize);
  }

  /** @inheritdoc */
  @computed public get hasNext(): boolean {
    return this._currentPage < this.totalPages - 1;
  }

  /** @inheritdoc */
  @computed public get hasPrevious(): boolean {
    return this._currentPage > 0;
  }

  /** @inheritdoc */
  @action public async loadPage(pageNumber: number): Promise<Array<T>> {
    if (pageNumber > this.pages.length - 1) {
      throw new InvalidStateError(
        `Order page fetch failed: The specified page does not exist.`
      );
    }

    const currentUser = await UserService.getCurrentUser();

    const { orders } = await OrderLookupService.getOrders({
      pageSize: this.pageSize,
      page: pageNumber,
      params: { ...this.lookupParams, userUUID: currentUser.uuid }
    });

    if (!orders) {
      throw new InvalidStateError(
        `Order page fetch failed: No orders were returned for the specified page.`
      );
    }

    this._pages[pageNumber] = orders as Array<T>;
    this._currentPage = pageNumber;

    return orders as Array<T>;
  }

  /** @inheritdoc */
  @action public async nextPage(): Promise<Array<T>> {
    if (this.hasNext) {
      return this.loadPage(this.currentPage + 1);
    }

    throw new InvalidOperationError(
      'Cannot load next page of order history: there is no next page.'
    );
  }

  /** @inheritdoc */
  @action public async previousPage(): Promise<Array<T>> {
    if (this.hasPrevious) {
      return this.loadPage(this.currentPage - 1);
    }

    throw new InvalidOperationError(
      'Cannot load previous page of order history: there is no previous page.'
    );
  }

  /** @inheritdoc */
  public toDTO(): DTO<IOrderHistory<T>> {
    return toStatelessImmutable({
      pages: [...this.pages],
      pageSize: this.pageSize,
      allItems: [...this.allItems],
      totalPages: this.totalPages,
      totalItems: this.totalItems,
      currentPage: this.currentPage,
      hasNext: this.hasNext,
      hasPrevious: this.hasPrevious,
      isLoadingNewPage: this.isLoadingNewPage,
      lookupParams: this.lookupParams
    });
  }
}
