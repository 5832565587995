import { FC } from 'react';

import { IPage } from '@/services/models/Page';

interface IStructuredDataProps {
  /**
   * The page to get the structured data from. Structured data is stored on the "ldjson" property.
   */
  page: IPage;
}

/**
 * A component for adding and holding structured data to the page. Structured data
 * describes the contents of a page in way that is consumed by Google and other serach services
 * to add "rich search results." See: https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data.
 * Additionally, Next.js provides some guidance to adding json+ld here:
 * https://nextjs.org/docs/app/building-your-application/optimizing/metadata#json-ld.
 */
export const StructuredData: FC<IStructuredDataProps> = ({ page }) => {
  const { pageMetaData } = page;

  if (pageMetaData?.ldjson) {
    return (
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger -- See comment above. This is the recommended way to add json+ld according to Next.js. User generated content should be sanitized on submission (e.g. Reviews).
        dangerouslySetInnerHTML={{
          __html: pageMetaData.ldjson
        }}
      />
    );
  }
  return null;
};
