'use client';

import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import { IRatings } from '@/services/models/ReviewsModel';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import { observer } from 'mobx-react-lite';
import { Link } from '../../core-ui/Link';
import RatingStars from '../../core-ui/RatingStars';
import { CommonErrorBoundary } from '../../errors/boundaries/CommonErrorBoundary';
import SkeletonLoader from '../../utility/SkeletonLoader';
import { Price } from '../Price';
import ProductDetailsVariant from '../ProductDetailsVariant';
import VariationSelector from '../VariationSelector';
import { WishlistHeart } from '../WishlistHeart';
import IVariationPanelProps from './IVariationPanelProps';
import S from './styles.base.module.scss';
import { useVariationSettings } from './useVariationSettings';

/** Variation panel to contain size/color selector, add to cart. */
export const DefaultVariationPanel = observer(function DefaultVariationPanel({
  variant = ProductDetailsVariant.Default,
  product,
  children
}: IVariationPanelProps) {
  const {
    isWishlistEnabled,
    isShowStarRatings,
    name,
    sku,
    ratings,
    priceModel,
    groupName
  } = useVariationSettings(variant, product);

  // TODO: consider showing a spinner if a product update is loading
  // if (!product) return <Spinner size={30} color="#43A047" />;

  const bottomVariationTypes = [
    DisplayVariationAttributeType.Color,
    DisplayVariationAttributeType.Size
  ];
  const topVariationTypes = product.allVariationTypes.filter(
    (type) => !bottomVariationTypes.includes(type)
  );

  const isPDP = variant === ProductDetailsVariant.Default;

  // TODO(dennis): refactor this when we have better SWR tools.
  const swrRatings =
    ratings instanceof StaleWhileRevalidate
      ? (ratings as StaleWhileRevalidate<IRatings>)
      : undefined;

  const ratingsUI = !swrRatings ? undefined : !swrRatings.pending ? (
    <RatingStars
      rating={swrRatings?.value?.averageRating ?? 0}
      count={swrRatings?.value?.numberOfRatings}
      starSize={15}
    />
  ) : (
    <SkeletonLoader className={S.skeletonLoader} />
  );

  return (
    <div className={S.variationPanel}>
      <div className={S.topSection}>
        <div className={S.group}>{groupName}</div>
        {isShowStarRatings ? (
          isPDP ? (
            <Link
              as="a"
              href="#reviews"
              className={S.link}
              onClick={() => {
                // TODO: refactor this to use a forward ref
                const el = document.querySelector(
                  '#reviews button'
                ) as HTMLElement;
                if (el) el.click();
              }}
            >
              {ratingsUI}
            </Link>
          ) : (
            <div>{ratingsUI}</div>
          )
        ) : undefined}
      </div>

      <div className={S.nameSection}>
        <div className={S.name}>{name}</div>
        {isWishlistEnabled && <WishlistHeart slug={sku} />}
      </div>
      <Price className={S.price} price={priceModel} />

      <CommonErrorBoundary>
        {topVariationTypes.map((type) => (
          <VariationSelector key={type} type={type} product={product} />
        ))}

        {/*
        If child content is provided, it should be placed here. This
        would typically be the product carousel, passed in for
        mobile/tablet display.
      */}
        {children}
        {bottomVariationTypes.map((type) => (
          <VariationSelector key={type} type={type} product={product} />
        ))}
      </CommonErrorBoundary>
    </div>
  );
});
