import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { msg, msgf } from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import ProductService from '@/services/isomorphic/ProductService';
import UserInteractionService, {
  EventType
} from '@/services/isomorphic/UserInteractionService';
import type { IBreadcrumb } from '@/services/models/Breadcrumb';
import type { ILineItem } from '@/services/models/Cart/LineItem';
import { ProductAvailabilityState } from '@/services/models/Inventory';
import type { IImage } from '@/services/models/Media/Image';
import type { IPrice } from '@/services/models/Price';
import {
  IProduct,
  IProductDetails,
  ProductModel
} from '@/services/models/Product';
import type { IRatings } from '@/services/models/ReviewsModel';
import type { ISizeChart } from '@/services/models/SizeChart';
import type { Asyncable, IAbortSignal, Nullable } from '@/type-utils';
import LazyValue from '@/utils/LazyValue';
import { InvalidStateError } from '@/utils/errors';
import { exhaustiveFallback } from '@/utils/function-utils';
import { removeUndefined } from '@/utils/object-utils';
import { makeAutoObservable } from 'mobx';
import type { NextRouter } from 'next/router';
import { PageService } from '@/services/isomorphic/PageService';
import { PageType } from '@/services/models/Page';
import CartVM from '../CartVM';
import GroupInventoryVM from '../Inventory/GroupInventoryVM';
import DisplayVariationAttributeType from './DisplayVariationAttributeType';
import type IDescriptionDisplay from './IDescriptionDisplay';
import type IDisplayProductAvailability from './IDisplayProductAvailability';
import type { DisplayVariationAttributeFromType } from './IDisplayVariationAttribute';
import type IProductDisplay from './IProductDisplay';
import type { IProductVM } from '.';
import { product_details_outOfStock } from "@/lang/__generated__/ahnu/product_details_outOfStock";
import { product_details_inStock } from "@/lang/__generated__/ahnu/product_details_inStock";
import { product_details_unavailable } from "@/lang/__generated__/ahnu/product_details_unavailable";
import { product_details_backorder } from "@/lang/__generated__/ahnu/product_details_backorder";
import { product_details_preorder } from "@/lang/__generated__/ahnu/product_details_preorder";
import { product_details_model } from "@/lang/__generated__/ahnu/product_details_model";

/**
 * Options for updating the product view model from the router.
 */
export interface IUpdateFromRouterOptions {
  /**
   * A signal to cancel the update operation.
   */
  signal?: IAbortSignal;
}

const DISPLAY_VARIATION_TYPE_ORDER = [
  DisplayVariationAttributeType.TargetDemographic,
  DisplayVariationAttributeType.ShoeHeight,
  DisplayVariationAttributeType.Material,
  DisplayVariationAttributeType.Color,
  DisplayVariationAttributeType.Size
];

const STYLE_PARAM = 'style';

/**
 * A view model for a product display.
 */
export default class ProductVM implements IProductVM {
  private _groupInventory: LazyValue<GroupInventoryVM>;

  /**
   * Creates a new instance of `ProductVM`.
   * @param productDisplay - The product display.
   * @param isPDP - If this `ProductVM` will be used in a PDP.
   */
  public constructor(
    private productDisplay: IProductDisplay,
    public readonly isPDP = false
  ) {
    this._groupInventory = new LazyValue(
      () => new GroupInventoryVM(productDisplay.variationUPCs)
    );

    makeAutoObservable(this);
  }

  /** @inheritdoc */
  public get variationUPCs(): ReadonlyArray<string> {
    return this.productDisplay.variationUPCs;
  }

  /** @inheritdoc */
  public get styleNumber(): string {
    return this._currentProduct.styleNumber;
  }

  /** @inheritdoc */
  public get areAllVariantsOOSorUnavailable(): boolean {
    return this._groupInventory.value.isEntireGroupOOSorUnavailable();
  }

  /** @inheritdoc */
  public determineResultingOnlineStatusOnAttributeSelection(
    variationType: DisplayVariationAttributeType,
    value: string
  ): boolean {
    return this.productDisplay.determineResultingOnlineStatusOnAttributeSelection(
      variationType,
      value
    );
  }

  /** @inheritdoc */
  public determineResultingAvailabilityOnAttributeSelection(
    variationType: DisplayVariationAttributeType,
    value: string
  ): ProductAvailabilityState {
    const upc = this.productDisplay.determineResultingUpcOnAttributeSelection(
      variationType,
      value
    );

    if (!upc) {
      return ProductAvailabilityState.Unknown;
    }

    return this._groupInventory.value.getAvailabilityForUPC(upc);
  }

  /** @inheritdoc */
  // Note: the private version of this getter is non-deprecated
  // since it is used internally as an implementation detail.
  public get currentProduct(): IProduct {
    return this._currentProduct;
  }

  /**
   * The current product being displayed.
   * @returns The current product being displayed.
   */
  private get _currentProduct(): IProduct {
    return this.productDisplay.currentProduct;
  }

  /** @inheritdoc */
  public get price(): Nullable<IPrice> {
    return this._currentProduct.price;
  }

  /** @inheritdoc */
  public get sku(): string {
    return this._currentProduct.sku;
  }

  /** @inheritdoc */
  public get group(): Nullable<string> {
    return this._currentProduct.group;
  }

  /** @inheritdoc */
  public get ratings(): Asyncable<Nullable<IRatings>> {
    return this._currentProduct.ratings;
  }

  /** @inheritdoc */
  public get sizeChart(): Nullable<ISizeChart> {
    return this._currentProduct.sizeChart;
  }

  /** @inheritdoc */
  public get defaultColor(): string {
    const productModel = ProductModel.from(this._currentProduct);

    return productModel.defaultColor;
  }

  /**
   * Determines if the product is purchasable, and if not, throws an appropriate
   * error.
   *
   * Call this method to stop execution when the product is not purchasable.
   *
   * @throws An {@link InvalidStateError} if the current product is {@link _purchasableGuard not purchasable}.
   */
  private _purchasableGuard(): void {
    if (!this.isPurchasable) {
      if (!this.productDisplay.currentProduct.isCompleteVariant) {
        throw new InvalidStateError(
          `Cannot add incomplete variant to cart: ${this.sku}`
        );
      }

      const inventoryConfig = ConfigurationService.getConfig('inventory');
      const allowBackorder = inventoryConfig.getSetting('allowBackorder').value;
      const allowPreorder = inventoryConfig.getSetting('allowPreorder').value;

      const acceptableInventoryStates = [ProductAvailabilityState.InStock];

      if (allowBackorder) {
        acceptableInventoryStates.push(ProductAvailabilityState.Backorder);
      }

      if (allowPreorder) {
        acceptableInventoryStates.push(ProductAvailabilityState.Preorder);
      }

      if (!acceptableInventoryStates.includes(this.availability.status)) {
        throw new InvalidStateError(
          `Cannot add variant "${this.sku}" to cart since it's either out of` +
            ' stock, unavailable, or its availability status cannot be' +
            ' determined.'
        );
      }
    }
  }

  /** @inheritdoc */
  public async addToCart(cart: CartVM): Promise<void> {
    this._purchasableGuard();
    await cart.addItem(this._currentProduct);
  }

  /** @inheritdoc */
  public async replaceInCart(
    cart: CartVM,
    productLineItem: ILineItem
  ): Promise<void> {
    this._purchasableGuard();
    await cart.replaceLineItem(productLineItem, this._currentProduct);
  }

  /** @inheritdoc */
  public get allVariationTypes(): ReadonlyArray<DisplayVariationAttributeType> {
    const variationTypes = [...this.productDisplay.allVariationTypes];

    // ensure consistent order for rendering
    const sortedVariationTypes = variationTypes.sort(
      (dvat1, dvat2) =>
        DISPLAY_VARIATION_TYPE_ORDER.indexOf(dvat1) -
        DISPLAY_VARIATION_TYPE_ORDER.indexOf(dvat2)
    );

    return sortedVariationTypes;
  }

  /** @inheritdoc */
  public get urlToProduct(): URL {
    const { origin } = EnvironmentService.url;
    return new URL(`/p/${this._currentProduct.sku}`, origin);
  }

  /** @inheritdoc */
  public get name(): string {
    // TODO: In the future, we may want to rely on configs or other metadata to determine the
    // name to display. For example, for the Sequence platform, instead of displaying the current
    // product's name (e.g. "Sequence 1 Low", "Sequence 1 Mid", etc.), we may want to display a
    // more generic name(e.g. "Sequence 1").
    return this._currentProduct.name;
  }

  /** @inheritdoc */
  public get images(): ReadonlyArray<IImage> {
    return this._currentProduct.images;
  }

  /** @inheritdoc */
  public get details(): IProductDetails {
    return this.productDisplay.currentProduct.details;
  }

  /** @inheritdoc */
  public get selectedVariations(): {
    [key in DisplayVariationAttributeType]?: string | undefined;
  } {
    return this.productDisplay.selectedVariations;
  }

  /** @inheritdoc */
  public get breadcrumbs(): ReadonlyArray<IBreadcrumb> {
    // TODO(dennis): move breadcrumbs to view model.
    return ProductModel.from(this._currentProduct).breadcrumbs;
  }

  /** @inheritdoc */
  public get availability(): IDisplayProductAvailability {
    if (this.areAllVariantsOOSorUnavailable) {
      return {
        status: ProductAvailabilityState.OutOfStock,
        text: msg(product_details_outOfStock)
      };
    }

    const { availability } = this.productDisplay;

    const inventoryConfig = ConfigurationService.getConfig('inventory');
    const allowBackorder = inventoryConfig.getSetting('allowBackorder').value;
    const allowPreorder = inventoryConfig.getSetting('allowPreorder').value;

    let text: string | null = null;

    switch (availability) {
      case ProductAvailabilityState.InStock:
        text = msg(product_details_inStock);
        break;

      case ProductAvailabilityState.OutOfStock:
        text = msg(product_details_outOfStock);
        break;
      case ProductAvailabilityState.Unavailable:
        text = msg(product_details_unavailable);
        break;

      case ProductAvailabilityState.Unknown:
      case ProductAvailabilityState.Pending:
        break;
      case ProductAvailabilityState.Backorder:
        text = allowBackorder
          ? msg(product_details_backorder)
          : msg(product_details_outOfStock);
        break;
      case ProductAvailabilityState.Preorder:
        text = allowPreorder
          ? msg(product_details_preorder)
          : msg(product_details_outOfStock);
        break;
      default:
        exhaustiveFallback(availability, () => {
          LoggerService.error(
            `Invalid availability state for ${this.sku}: ${availability}`
          );
        });
    }

    return { status: availability, text };
  }

  /** @inheritdoc */
  public get isPurchasable(): boolean {
    return this.productDisplay.isPurchasable;
  }

  /** @inheritdoc */
  public get description(): IDescriptionDisplay {
    const currentProduct = ProductModel.from(this._currentProduct);

    const isNameTruncated = ConfigurationService.getConfig('ui').getSetting(
      'pdp.description.truncateName'
    ).value;

    const preamble = msgf(product_details_model, {
      model:
        !isNameTruncated && currentProduct.isCompleteVariant
          ? currentProduct.sku
          : currentProduct.styleNumber
    });

    return {
      preamble,
      body: currentProduct.description
    };
  }

  /** @inheritdoc */
  public async selectVariationAttribute(
    variationType: DisplayVariationAttributeType,
    value: string
  ): Promise<void> {
    this.productDisplay = await this.productDisplay.selectVariation(
      variationType,
      value
    );

    // Send data to GTM when variation is selected
    UserInteractionService.makeAction({
      action: EventType.ProductUpdateVariation,
      product: this._currentProduct
    });
  }

  /** @inheritdoc */
  public reportProductPersonalizationPage(): void {
    const { page } = PageService;

    if (!page) {
      LoggerService.warn(
        `Page is not set when changing product variation to style number: ${this._currentProduct.styleNumber} at location: ${window.location.href}`
      );
    }

    UserInteractionService.makeAction({
      action: EventType.ProductUpdateStyle,
      page: page ?? { url: window.location.href, pageType: PageType.Unknown },
      products: [this._currentProduct]
    });
  }

  /** @inheritdoc */
  public determineResultingUpcOnAttributeSelection(
    variationType: DisplayVariationAttributeType,
    value: string
  ): Nullable<string> {
    return this.productDisplay.determineResultingUpcOnAttributeSelection(
      variationType,
      value
    );
  }

  /** @inheritdoc */
  public getVariationAttributesByType<T extends DisplayVariationAttributeType>(
    type: T,
    onlineOnly = true
  ): ReadonlyArray<DisplayVariationAttributeFromType<T>> {
    const variationAttributes = this.productDisplay.getVariationsByType(type);

    if (onlineOnly) {
      return this.filterOfflineVariations(variationAttributes);
    }

    return variationAttributes;
  }

  /** @inheritdoc */
  public filterOfflineVariations<T extends DisplayVariationAttributeType>(
    variationAttributes: ReadonlyArray<DisplayVariationAttributeFromType<T>>
  ): ReadonlyArray<DisplayVariationAttributeFromType<T>> {
    return variationAttributes.filter(({ type, value }) => {
      const onlineStatus =
        this.productDisplay.determineResultingOnlineStatusOnAttributeSelection(
          type,
          value
        );

      return onlineStatus;
    });
  }

  /** @inheritdoc */
  public hasVariationAttributeSelected(
    type: DisplayVariationAttributeType
  ): boolean {
    return this.productDisplay.selectedVariations[type] !== undefined;
  }

  /** @inheritdoc */
  public updateRouter(router: NextRouter): void {
    const { url } = EnvironmentService;

    const {
      [DisplayVariationAttributeType.Color]: productColor,
      [DisplayVariationAttributeType.Size]: size
    } = this.selectedVariations;

    const color = productColor ?? this.defaultColor;

    // Remove undefined values so that they don't appear in the URL
    const searchParamsInit = removeUndefined({
      // First add any params that are already present in the URL so they are
      // not lost.
      ...Object.fromEntries(url.searchParams),

      [STYLE_PARAM]: this._currentProduct.styleNumber,
      [DisplayVariationAttributeType.Color]: color,
      [DisplayVariationAttributeType.Size]: size
    });

    const searchParams = new URLSearchParams(searchParamsInit);
    const newURL = `${url.pathname}?${searchParams}`;

    // This if statement is necessary to prevent infinite rerenders.
    if (router.asPath !== newURL) {
      /* eslint-disable-next-line promise/catch-or-return -- there is no need to catch the promise here
      since we are not interested in the result. */
      router
        .replace(newURL, undefined, { shallow: true })
        // Optimization: fetch the new URL to have it already cached for the next visit
        // This is a low priority fetch since it is not needed for the current page
        .then(() =>
          fetch(newURL, { priority: 'low' }).catch(() => {
            LoggerService.warn(`Failed to fetch the URL: "${newURL}".`);
          })
        );
    }
  }

  /** @inheritdoc */
  public async updateFromRouter(
    { query }: NextRouter,
    options?: IUpdateFromRouterOptions
  ): Promise<void> {
    if ((typeof window === "undefined")) {
      return;
    }

    const style = query[STYLE_PARAM] as string | undefined;
    const color = query[DisplayVariationAttributeType.Color];
    const size = query[DisplayVariationAttributeType.Size];

    // build the resulting product display one step at a time.
    // we don't update the product display until the end to prevent
    // unnecessary rerenders.
    let result = this.productDisplay;

    // This signal should be checked after every async operation since it
    // may have been aborted in the meantime, and we can prevent unnecessary
    // follow-up work. As a result, if it has been aborted, we should return.
    const signal = options?.signal;

    if (
      typeof style === 'string' &&
      result.currentProduct.styleNumber !== style
    ) {
      try {
        const product = await ProductService.getProduct(style);

        // If the signal has been aborted, then the rest of this operation should be cancelled.
        if (signal?.aborted) return;

        result = result.withCurrentProduct(product);
      } catch (error) {
        LoggerService.warn(
          'Error while updating product display from style query. ' +
            `This may be due to a broken link: ${error}`
        );
      }
    }

    if (
      typeof color === 'string' &&
      result.selectedVariations[DisplayVariationAttributeType.Color] !== color
    ) {
      try {
        const newProductDisplay = await result.selectVariation(
          DisplayVariationAttributeType.Color,
          color
        );

        // If the signal has been aborted, then the rest of this operation should be cancelled.
        if (signal?.aborted) return;

        result = newProductDisplay;
      } catch (error) {
        LoggerService.warn(
          'Error while updating product display from color query. ' +
            `This may be due to a broken link: ${error}`
        );
      }
    }

    if (
      typeof size === 'string' &&
      result.selectedVariations[DisplayVariationAttributeType.Size] !== size
    ) {
      try {
        const newProductDisplay = await result.selectVariation(
          DisplayVariationAttributeType.Size,
          size
        );

        // If the signal has been aborted, then the rest of this operation should be cancelled.
        if (signal?.aborted) return;

        result = newProductDisplay;
      } catch (error) {
        LoggerService.warn(
          'Error while updating product display from size query. ' +
            `This may be due to a broken link: ${error}`
        );
      }
    }

    // update the product display only once all the async operations have completed
    this.productDisplay = result;
  }

  /**
   * @inheritdoc
   */
  public get hasAllGenderSizing(): boolean {
    return this.getVariationAttributesByType(
      DisplayVariationAttributeType.Size
    ).some((size) => {
      if (size.metadata.gender === 'allgender') {
        return true;
      }

      return false;
    });
  }
}
